// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-glossar-js": () => import("./../src/templates/Glossar.js" /* webpackChunkName: "component---src-templates-glossar-js" */),
  "component---src-templates-information-js": () => import("./../src/templates/Information.js" /* webpackChunkName: "component---src-templates-information-js" */),
  "component---src-templates-company-js": () => import("./../src/templates/Company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beratung-anfordern-js": () => import("./../src/pages/beratung-anfordern.js" /* webpackChunkName: "component---src-pages-beratung-anfordern-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informationen-js": () => import("./../src/pages/informationen.js" /* webpackChunkName: "component---src-pages-informationen-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-krankenkasse-js": () => import("./../src/pages/krankenkasse.js" /* webpackChunkName: "component---src-pages-krankenkasse-js" */),
  "component---src-pages-praemienrechner-js": () => import("./../src/pages/praemienrechner.js" /* webpackChunkName: "component---src-pages-praemienrechner-js" */)
}

